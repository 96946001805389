import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles<Theme, {}>((theme) =>
  createStyles({
    homepageSlider: {
      display: 'flex',
      flex: '1 1 100%',
      margin: '0 auto',
      maxWidth: 1170,
      position: 'relative',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        aspectRatio: 1,
        width: '100vw',
      },
    },
    homepageSliderCarousel: {
      '& .react-multi-carousel-dot-list': {
        margin: '0 0 26px 0',
      },
      width: '100%',
    },
    homepageSliderSwiper: {
      '& .swiperNavigationButton': {
        top: 'calc(50% - 20px)',
      },
      margin: '0 auto',
      maxWidth: 1170,
      position: 'relative',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
    },
  }),
);
