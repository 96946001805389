import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ColorsPalette } from '@themes/colors';
import { FontCollection } from '@themes/font';
import { zIndex } from '@themes/zIndex';

const slideHeight = 520;
const slideHeightMobile = 'auto';
const slideWidth = 1064;

const getStyles = makeStyles<Theme, {}>((theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      zIndex: zIndex.sliderButtons,
    },
    image: {
      backgroundColor: ColorsPalette.neutral1,
      display: 'block',
      height: '100%',
      position: 'relative',
      width: '100%',

      '& img': {
        [theme.breakpoints.down('md')]: {
          display: 'block',
          height: slideHeightMobile,
        },
        height: slideHeight,
        margin: '0 auto',
        maxWidth: slideWidth,
        position: 'relative',
        width: '100%',
      },
    },
    slide: {
      [theme.breakpoints.down('md')]: {
        height: slideHeightMobile,
      },
      alignItems: 'center',
      display: 'flex',
      height: slideHeight,
      justifyContent: 'center',
      margin: '0 auto',
      maxWidth: slideWidth,
      width: '100%',
    },
    slideButtons: {
      alignItems: 'center',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.36) -2.51%, rgba(0, 0, 0, 0) 83.05%)',
      bottom: '0px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      height: '116px',
      justifyContent: 'space-between',
      margin: '0px',
      maxWidth: slideWidth,
      padding: '0px 20px 0 36px',
      paddingTop: '44px',
      position: 'absolute',
      width: '100%',
      zIndex: zIndex.sliderButtons,
      [theme.breakpoints.down('md')]: {
        height: '46px',
        padding: '0px 8px',
      },
    },
    slideButtonsButton: {
      marginLeft: 'auto',
    },
    slideButtonsButtonText: {
      '&:hover': {
        color: ColorsPalette.hoverActionCTA,
      },
      background: ColorsPalette.coralPinkLight,
      borderRadius: '100px',
      color: ColorsPalette.primary,
      cursor: 'pointer',
      display: 'block',
      fontFamily: FontCollection.raleway,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
      padding: '12px 16px',
      position: 'relative',
      textDecoration: 'none',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        marginTop: '4px',
        padding: '6px 8px',
      },
    },
    slideButtonsTitle: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    slideButtonsTitleIcon: {
      backgroundImage: 'url(/svg/D.svg)',
      display: 'inline-block',
      height: '16px',
      position: 'relative',
      width: '19px',
    },
    slideButtonsTitleText: {
      color: 'white',
      fontFamily: FontCollection.inter,
      fontSize: '16px',
      fontWeight: 'bold',
      height: '16px',
      lineHeight: '16px',
      paddingLeft: '15px',
      verticalAlign: 'middle',
    },
    slideContainer: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      maxWidth: slideWidth,
      width: '100%',
    },
  })
);

export default getStyles;
