import classNames from 'classnames';
import React from 'react';

import ErrorBoundary from '@components/common/ErrorBoundary';
// import Carousel from '@components/common/ReactMultiCarousel';
import SwiperComponent from '@components/common/Swiper';
import { HomepageSliderImage } from '@components/pages/home/slider/Slide';
import { useScreenSize } from '@hooks/useScreenSize';
import { IHomePageComponentDataObject } from '@interfaces/home';

import HomepageStyles from './styles';

interface IHbsMedia {
  media: string;
  link: string;
  height?: number;
  width?: number;
}

export interface IHomepageSliderComponent {
  bannerLink: string;
  button1Link: string;
  button1Text: string;
  button2Link: string;
  button2Text: string;
  buttonsPositionLeft: number;
  buttonsPositionTop: number;
  buttonsWidth: number;
  hbsmedia: IHbsMedia[];
  show: boolean;
  showInApp: boolean;
  showInMobile: boolean;
  title: string;
  type: string;
  automaticimageswitching?: boolean;
  interval?: number;
}

interface IHomepageSliderProps {
  component: IHomePageComponentDataObject;
  className?: string;
}

const HomepageSlider: React.FunctionComponent<IHomepageSliderProps> = (props) => {
  const { isMobile } = useScreenSize();
  const classes = HomepageStyles();
  const defaultComponent: IHomepageSliderComponent = {
    bannerLink: '',
    button1Link: '',
    button1Text: '',
    button2Link: '',
    button2Text: '',
    buttonsPositionLeft: 0,
    buttonsPositionTop: 0,
    buttonsWidth: 0,
    hbsmedia: [],
    show: true,
    showInApp: true,
    showInMobile: true,
    title: '',
    type: '',
  };
  const component: IHomepageSliderComponent = { ...defaultComponent, ...props.component };

  const hbsMedia: IHbsMedia[] = Array.isArray(component.hbsmedia) ? component.hbsmedia : [];
  const itemsList = hbsMedia.map(({ media, link, height, width }, index) => {
    return <HomepageSliderImage key={`HomepageSliderImage${index}`}
                                path={media}
                                alt={component?.title || 'Home slider'}
                                title={component?.title}
                                link={link}
                                button1Text={component?.button1Text}
                                button1Link={component?.button1Link}
                                button2Text={component?.button2Text}
                                button2Link={component?.button2Link}
                                buttonsWidth={component?.buttonsWidth}
                                buttonsPositionTop={component?.buttonsPositionTop}
                                buttonsPositionLeft={component?.buttonsPositionLeft}
                                aspectRatio={width && height ? `${width}/${height}` : null}
    />;
  });
  return (
    <ErrorBoundary>
      <div className={classNames(classes.homepageSliderSwiper, 'home-slider', props.className)}>
        <SwiperComponent autoplay={component?.automaticimageswitching || false}
                         slides={itemsList}
                         slidesPerView={1}
                         spaceBetween={0}
                         pagination={true}
                         navigation={!isMobile}
                         loop={true}
                         delay={(component?.interval || 3) * 1000}
        />
      </div>
      {/*
      <div className={`${classes.homepageSlider} home-slider`}>
        <Carousel
          autoPlay={component?.automaticimageswitching || false}
          autoPlaySpeed={(component?.interval || 5) * 1000}
          arrows={!isMobileView && component.show}
          infinite={true}
          swipeable={true}
          disableMove={true}
          ssr={true}
          showDots={true}
          deviceType="desktop"
          draggable={false}
          itemClass="carousel-item"
          minimumTouchDrag={5}
          renderButtonGroupOutside={true}
          responsive={{
            desktop: {
              breakpoint: { max: 9000, min: 461 },
              items: 1,
            },
            mobile: {
              breakpoint: { max: 460, min: 0 },
              items: 1,
            },
          }}
          className={classes.homepageSliderCarousel}
        >
          {itemsList}
        </Carousel>
      </div>
      */}
    </ErrorBoundary>
  );
};

export default HomepageSlider;
