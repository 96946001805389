import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IResult } from 'ua-parser-js';

import { IStore } from '@interfaces/store';

interface IUseScreenSizeResult {
  isMobile: boolean;
}

export const useScreenSize = (): IUseScreenSizeResult => {
  const userAgent: Partial<IResult> | null = useSelector((state: IStore) => state.context.userAgent);
  const deviceType = userAgent?.device?.type;
  const isMobileByUserAgent = useMemo(()=> deviceType && ['tablet', 'mobile'].includes(deviceType) || false, [deviceType])

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [isMobile, setIsMobile] = useState<boolean>(isMobileByUserAgent);

  useEffect(() => {
    setIsMobile(isMobileView);
  }, [isMobileView]);

  return {
    isMobile,
  };
};
